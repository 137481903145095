export class AddCopyright {
  constructor(copyrightText) {
    this.copyrightText = copyrightText
  }

  getListener() {
    return (event) => {
      const range = window.getSelection().getRangeAt(0)
      const rangeContents = range.cloneContents()
      const helper = document.createElement("div")
      helper.appendChild(rangeContents)

      const tableIndex = helper.innerHTML.indexOf("<table")

      if (tableIndex >= 0) {
        helper.innerHTML = helper.innerHTML.slice(0, tableIndex - 1)
      }

      const commentIndex = helper.innerHTML.indexOf("<!--")

      if (commentIndex >= 0) {
        helper.innerHTML = helper.innerHTML.slice(0, commentIndex - 1)
      }

      event.clipboardData.setData(
        "text/plain",
        `${this.copyrightText}\n\n${helper.innerText?.trim()}\n\n${
          this.copyrightText
        }`,
      )

      event.preventDefault()
    }
  }
}
