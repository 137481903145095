/**
 * Create iOS style stackable list/content headers
 * Made by Mike Smedley / @smdly
 *
 * Arguments:
 * @body: The parent container holding your title.  A container is needed for each individual title
 * @title: The title that you want to scroll down the page
 * @margin: Because the title gets absolutely positioned when it hits the bottom, you might need to supply a negative margin to counter any padding/margin on your parent element
 * @offset: If you have other fixed elements above, you can specify the offset here
 */

import jQuery from "jquery";

(function ($) {

    function calculateStacking(elem, settings, forceCalculate) {
        var container = elem.parent();

        //Get the top of moving header parent (the container)
        var offset = container.offset();

        var elementHeight = elem.outerHeight(true);
        var parentBottom = offset.top + container.outerHeight(true);

        // Bottom of the "body"
        var topOffset = 0;

        if (settings.offset && (typeof settings.offset == "function")) {
            topOffset = settings.offset();
        }
        else {
            topOffset = settings.offset;
        }

        var hideTrigger = (parentBottom + settings.margin) - topOffset;


        if (($(window).scrollTop() + elementHeight) >= hideTrigger) {
            /* stop at bottom */
            elem.removeClass('fixed').addClass('absolute').removeAttr('style');
        }
        else if ($(document).scrollTop() >= ((offset.top) - topOffset) && ( parentBottom - $(document).scrollTop() - topOffset >= elementHeight)) {

            /* scroll from top */
            if (forceCalculate || !elem.hasClass("fixed")) {
                const $awidth = container.outerWidth() - (elem.outerWidth(true) - elem.outerWidth());
                elem.removeClass("absolute").addClass('fixed active').css({'width': $awidth, 'top': topOffset + "px"});

                elem.siblings().removeClass('active');
            }
        }
        else {
            elem.removeClass('fixed active absolute');
            elem.removeAttr('style');
        }
    }

    function stackToBottom(elem, settings, forceCalculate) {
        var h = $(window).height();
        var s = $(window).scrollTop();

        var container = $(settings.body);


        var a = container.outerHeight();
        var b = container.offset().top;

        var containerBottom = a + b - h - s;

        if(containerBottom > 0) {
            $(settings.title_container).css("height", elem.outerHeight() + "px");
            elem.css("width", container.width() + "px");
            elem.toggleClass("fixed", true);
        }
        else {
            $(settings.title_container).css("height", "auto");
            elem.toggleClass("fixed", false);
            elem.css("width", "auto");
        }

    }

    $.fn.stacks = function (options) {

        var settings = $.extend({
            body: '.stickyparent',
            title: '.header',
            margin: 0,
            offset: 0,
            stack_to: "top",
            title_container: ".header-container"
        }, options);

        $.each(this.find(settings.body + ' ' + settings.title), function (k, v) {
            if(settings.stack_to === "top") {
                $(window).resize(function () {
                    calculateStacking($(v), settings, true);
                });

                $(window).scroll(function () {
                    calculateStacking($(v), settings, false);
                });

                setTimeout(function () {
                    calculateStacking($(v), settings, true);
                }, 1000);
            }
            else if(settings.stack_to === "bottom") {
                $(window).resize(function () {
                    stackToBottom($(v), settings, true);
                });

                $(window).scroll(function () {
                    stackToBottom($(v), settings, false);
                });

                setTimeout(function () {
                    stackToBottom($(v), settings, true);
                }, 1000);
            }
        })
    };
})(jQuery);
