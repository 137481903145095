import "jstree"
import { AddCopyright } from "./add-copyright"

require("imports-loader?define=>false!typeahead.js/dist/typeahead.jquery.min.js")
const Bloodhound = require("imports-loader?define=>false!typeahead.js/dist/bloodhound.min.js")

window.Bloodhound = Bloodhound

window.$ = window.jQuery = $

function isTouchDevice() {
  return (
    ("ontouchstart" in window ||
      (window.DocumentTouch && document instanceof DocumentTouch)) == true
  )
}

window.isTouchDevice = isTouchDevice

function toggleListGrid(
  mode,
  otherMode,
  toggleElement,
  otherToggleElement,
  listContainer,
) {
  $(toggleElement).toggleClass("active", true)
  $(otherToggleElement).toggleClass("active", false)
  $(listContainer).toggleClass(mode, true)
  $(listContainer).toggleClass(otherMode, false)

  $.ajax({
    url: "/settings.php?api=true",
    type: "POST",
    data: `product_container=${mode}`,
  })
}

window.toggleListGrid = toggleListGrid

function shopping_cart_over() {
  /*   if ('ontouchstart' in document.documentElement) {
        // if mobile we use a backdrop because click events don't delegate
        $(document.createElement('div'))
                .addClass('dropdown-backdrop')
                .insertAfter($(this))
                .on('click', shopping_cart_out);
    }
   */

  $("#shopping_cart_details").toggleClass("hidden", false)
  $("#shopping_cart_details div.product_list_container").scrollTop(0)
}

window.shopping_cart_over = shopping_cart_over

function shopping_cart_out() {
  $("#shopping_cart_details").toggleClass("hidden", true)
}

window.shopping_cart_out = shopping_cart_out

function updateSelectedOptionInfo() {
  const selectedOptions = $("input.product-option:checked")
  let t = ""

  for (let i = 0; i < selectedOptions.length; i++) {
    if (i > 0) {
      t += ", "
    } else {
      t += "Valittuna: "
    }

    t += `<span class='selected-option'>${$(selectedOptions[i])
      .parent()
      .find("label")
      .text()}</span>`
  }

  $("#selected-options-container").html(t)
}

function hideAddToCartPopover() {
  const $el = $("#in_cart_button")

  $el.popover("hide")
}

function acceptCookieConsent() {
  $.post("/cookie_usage.php", {
    action: "accept",
  }).always(function () {
    $("#cookie-consent-banner").fadeOut(500, function () {
      $(this).remove()
    })
  })
}

window.acceptCookieConsent = acceptCookieConsent

$(document).ready(function () {
  // Handle click on all .mp-href marked elements
  $(".mp-href").click(function (event) {
    if (event.which == 1) {
      const href = $(this).data("href")
      const type = $(this).data("type")

      if (typeof PAGE_DATA !== "undefined" && type === "product") {
        const prodName = $(this).data("productName")
        const prodId = $(this).data("productId")
        const position = $(this).data("listPosition")
        const { category } = PAGE_DATA

        const product = {
          id: prodId,
          name: prodName,
          position,
          category,
        }

        mpTracker.trackProductClick(product, PAGE_DATA.ga_list, function () {
          document.location.href = href
        })
      } else {
        document.location.href = href
      }

      event.preventDefault()
    }
  })
})

// jQuery plugin to prevent double submission of forms
jQuery.fn.preventDoubleSubmission = function () {
  $(this).on("submit", function (e) {
    const $form = $(this)

    if ($form.data("submitted") === true) {
      // Previously submitted - don't submit again
      e.preventDefault()
    } else {
      // Mark it so that the next submit can be ignored
      $form.data("submitted", true)
    }
  })

  // Keep chainability
  return this
}

function preventSameValueOnFields(firstField, secondField) {
  firstField.change(function () {
    if (firstField.val().toUpperCase() == secondField.val().toUpperCase()) {
      secondField.val("").trigger("input")
    }
  })
}

window.preventSameValueOnFields = preventSameValueOnFields

var mpTracker = {
  getGA4Item(product) {
    return {
      item_id: product.id,
      item_name: product.name,
      quantity: product.quantity,
      item_brand: product.brand,
      item_category: product.category,
      item_variant: product.variant,
      price: product.price,
      currency: "EUR",
    }
  },
  trackPurchase(products, order_number, total, shipping, tax) {
    dataLayer.push({ ecommerce: null })
    dataLayer.push({
      event: "ua:purchase",
      ecommerce: {
        purchase: {
          actionField: {
            id: order_number,
            revenue: total,
            shipping,
            tax,
          },
          products,
        },
      },
    })

    dataLayer.push({ ecommerce: null }) // Clear the previous ecommerce object.
    dataLayer.push({
      event: "purchase",
      ecommerce: {
        transaction_id: order_number,
        value: total,
        tax,
        shipping,
        currency: "EUR",
        items: products.map((p) => {
          return this.getGA4Item(p)
        }),
      },
    })
  },

  getGA4CheckoutEventName(step) {
    switch (step) {
      case 1:
        return "begin_checkout"
      case 2:
        return "add_shipping_info"
      case 3:
        return "add_payment_info"
      default:
        return undefined
    }
  },

  trackCheckout(step, option, products, ga4EventData) {
    dataLayer.push({ ecommerce: null }) // Clear the previous ecommerce object.
    dataLayer.push({
      event: "checkout",
      ecommerce: {
        checkout: {
          actionField: { step, option },
          products,
        },
      },
    })

    const ga4EventName = this.getGA4CheckoutEventName(step)

    dataLayer.push({ ecommerce: null })
    dataLayer.push({
      event: ga4EventName,
      ecommerce: {
        ...(ga4EventData || {}),
        items: products.map((p) => {
          return this.getGA4Item(p)
        }),
      },
    })
  },

  sendGACheckoutOption(step, option) {
    dataLayer.push({ ecommerce: null }) // Clear the previous ecommerce object.
    dataLayer.push({
      event: "checkoutOption",
      ecommerce: {
        checkout_option: {
          actionField: { step, option },
        },
      },
    })
  },

  trackProductView(product) {
    dataLayer.push({ ecommerce: null }) // Clear the previous ecommerce object.
    dataLayer.push({
      ecommerce: {
        detail: {
          products: [product],
        },
      },
    })

    dataLayer.push({ ecommerce: null })

    dataLayer.push({
      event: "view_item",
      ecommerce: {
        items: [this.getGA4Item(product)],
      },
    })

    fbq("track", "ViewContent", {
      content_type: "product",
      content_name: product.name,
      content_category: product.category,
      content_ids: [product.id],
    })
  },

  trackProductsImpressions(impressions) {
    dataLayer.push({ ecommerce: null })
    dataLayer.push({
      ecommerce: {
        impressions,
      },
    })
    dataLayer.push({ ecommerce: null })
    dataLayer.push({
      event: "view_item_list",
      ecommerce: {
        items: impressions.map((p) => {
          return this.getGA4Item(p)
        }),
      },
    })
  },

  trackAddToCart(products, nonInteraction) {
    dataLayer.push({ ecommerce: null })
    dataLayer.push({
      event: "ua:addToCart",
      ecommerce: {
        add: {
          products,
        },
      },
    })
    dataLayer.push({ ecommerce: null })
    dataLayer.push({
      event: "ga4:addToCart",
      ecommerce: {
        items: products.map((p) => {
          return this.getGA4Item(p)
        }),
      },
    })

    fbq("track", "AddToCart", {
      content_type: "product",
      contents: products.map((p) => {
        return {
          id: p.id,
          quantity: p.quantity,
        }
      }),
    })
  },

  trackRemoveFromCart(products, nonInteraction) {
    dataLayer.push({ ecommerce: null })
    dataLayer.push({
      event: "ua:removeFromCart",
      ecommerce: {
        remove: {
          products,
        },
      },
    })
    dataLayer.push({ ecommerce: null })
    dataLayer.push({
      event: "ga4:removeFromCart",
      ecommerce: {
        items: products.map((p) => {
          return this.getGA4Item(p)
        }),
      },
    })
  },
  trackProductClick(product, list, hitCallback) {
    if (window.google_tag_manager) {
      dataLayer.push({ ecommerce: null })
      dataLayer.push({
        event: "productClick",
        ecommerce: {
          click: {
            actionField: { list },
            products: [product],
          },
        },
        eventCallback() {
          hitCallback()
        },
      })
    } else {
      hitCallback()
    }
  },
}

window.mpTracker = mpTracker

function displayPickupLocationMap(lat, lon, elementId) {
  if (lat && lon) {
    $(`#${elementId}`).removeClass("hidden")

    setTimeout(function () {
      const location = { lat, lng: lon }

      const map = new google.maps.Map(document.getElementById(elementId), {
        zoom: 16,
        center: location,
        zoomControl: true,
        fullscreenControl: false,
      })
      const marker = new google.maps.Marker({
        position: location,
        map,
      })

      setTimeout(function () {
        map.panBy(0, -30)
      }, 0)
    }, 0)
  } else {
    $(`#${elementId}`).addClass("hidden")
  }
}

window.displayPickupLocationMap = displayPickupLocationMap

const shoppingCartHoverIntentConfig = {
  over: shopping_cart_over,
  timeout: 800,
  out: shopping_cart_out,
}

window.shoppingCartHoverIntentConfig = shoppingCartHoverIntentConfig

const shoppingCartDetailsHoverIntentConfig = {
  over() {
    $("#shopping_cart_details").toggleClass("super-sticky", true)
  },
  timeout: 800,
  out() {
    $("#shopping_cart_details").toggleClass("super-sticky", false)
  },
}

window.shoppingCartDetailsHoverIntentConfig = shoppingCartDetailsHoverIntentConfig

$(document).ready(function () {
  $("#category-tree")
    .on("changed.jstree", function (e, data) {
      const tree = $(this).jstree()

      if (tree.is_parent(data.node)) {
        tree.toggle_node(data.node)
      } else {
        const { href } = data.node.a_attr
        document.location.href = href
      }
    })
    .jstree({
      core: {
        multiple: false,
        dblclick_toggle: false,
      },
    })

  $("button.sidemenu-toggle").click(function () {
    const stateObj = { foo: "bar" }

    history.pushState(
      stateObj,
      "",
      `${
        window.location.pathname + (location.search ? location.search : "")
      }#sidemenu`,
    )

    $("body").toggleClass("sidemenu-visible")

    $("body").removeClass("shopping-cart-visible")
    shopping_cart_out()

    return false
  })

  $(window).on("hashchange", function (ev) {
    if ($("body.sidemenu-visible").length > 0) {
      $("body").toggleClass("sidemenu-visible", false)
    }
  })

  $(document).on("click", "button.searchrow-toggle", function () {
    $("body").toggleClass("searchrow-visible")
    $("#search-input").focus()
  })

  $("#body-overlay, #close-sidemenu").click(function () {
    $("#sidemenu").scrollTop(0)

    $("body").removeClass("sidemenu-visible")
  })

  $("header").on("affix.bs.affix", function () {
    $("#affix-placeholder").css("height", $("header").height() + 5)
  })

  $("header").on("affix-top.bs.affix", function () {
    $("#affix-placeholder").css("height", 0)
  })

  function getTopOffset() {
    const w = $(window).width()
    let topOffset = 25

    if (w < 768) {
      topOffset = 1
    }

    return topOffset
  }

  $("header").affix({
    offset: {
      top: getTopOffset,
    },
  })
  if (isTouchDevice() === false) {
    $("#cart-btn").hoverIntent(shoppingCartHoverIntentConfig)
    $("#shopping_cart_details").hoverIntent(
      shoppingCartDetailsHoverIntentConfig,
    )

    $(
      "#shopping_cart div.shopping_cart_details_attributes div.stock-info[title]",
    ).tooltip({ placement: "auto right", viewport: "body" })
  } else {
    $("#cart-btn").click(function (e) {
      e.preventDefault()
      $("body").toggleClass("shopping-cart-visible")
      shopping_cart_over()
    })

    $("div.shopping-cart-overlay").click(function () {
      $("body").removeClass("shopping-cart-visible")
      shopping_cart_out()
    })
  }
})

function initializeFacebookWidget(locale) {
  ;(function (d, s, id) {
    let js
    const fjs = d.getElementsByTagName(s)[0]
    if (d.getElementById(id)) return
    js = d.createElement(s)
    js.id = id
    js.src = `//connect.facebook.net/${locale}/sdk.js#xfbml=1&version=v2.6`
    fjs.parentNode.insertBefore(js, fjs)
  })(document, "script", "facebook-jssdk")
  ;(function ($) {
    let a = null
    $(window).resize(function () {
      if (a != null) {
        clearTimeout(a)
      }

      a = setTimeout(function () {
        FB.XFBML.parse()
      }, 1000)
    })
  })(jQuery)
}

window.initializeFacebookWidget = initializeFacebookWidget

function cleanLocalStorage() {
  try {
    const ls = window.localStorage

    ls.setItem("~~~", "!")
    ls.removeItem("~~~")

    if (ls.getItem("lsc") == "1") {
      return
    }

    let len = ls.length
    let i

    const keys = []

    for (i = 0; i < len; i++) {
      const key = ls.key(i)

      if (key.startsWith("__/search.php?q=")) {
        keys.push(key)
      }
    }
    len = keys.length

    for (i = 0; i < len; i++) {
      ls.removeItem(keys[i])
    }
    ls.setItem("lsc", "1")
  } catch (err) {}
}

window.cleanLocalStorage = cleanLocalStorage

document.addEventListener("DOMContentLoaded", function () {
  $('[data-toggle="shipping-popover"]').webuiPopover({ closeable: true })
  $('[data-toggle="shipping-popover-sidemenu"]').webuiPopover({
    closeable: true,
    style: "shipping-popover-sidemenu",
    offsetLeft: 20,
    arrow: false,
  })
})

function fallbackCopyTextToClipboard(text) {
  const textArea = document.createElement("textarea")
  textArea.value = text

  // Avoid scrolling to bottom
  textArea.style.top = "0"
  textArea.style.left = "0"
  textArea.style.position = "fixed"

  document.body.appendChild(textArea)
  textArea.focus()
  textArea.select()

  try {
    const successful = document.execCommand("copy")
  } catch (err) {}

  document.body.removeChild(textArea)
}

function copyToClipboard(text) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text)
    return
  }
  navigator.clipboard.writeText(text).then(
    function () {},
    function (err) {},
  )
}

window.copyTextToClipboard = copyToClipboard

function escapeSelectorFunc(selector) {
  return !selector ? null : selector.replace(/(:|\.|\[|\]|,|=|@|{|})/g, "\\$1")
}

window.escapeSelector = escapeSelectorFunc

window.mpCopyListener = AddCopyright
