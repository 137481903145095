import $ from "jquery";

$(function() {
    $("body").on("input propertychange", ".floating-label-form-group", function(e) {
        $(this).toggleClass("floating-label-form-group-with-value", !!$(e.target).val());
    }).on("focus", ".floating-label-form-group", function() {
        $(this).addClass("floating-label-form-group-with-focus");
    }).on("blur", ".floating-label-form-group", function() {
        $(this).removeClass("floating-label-form-group-with-focus");
    });

    $(".floating-label-form-group").filter(function() {

        var inputs = $(this).find("input");
        return inputs.length > 0 && inputs[0].value
    }).toggleClass("floating-label-form-group-with-value", true);

});